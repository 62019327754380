//
// Deeply inspired from https://github.com/mozilla/serviceworker-cookbook/blob/master/push-simple/index.js
// and https://web-push-book.gauntface.com/
//

import config from '@/config';

let swRegistration: any = null;

// This function is needed because Chrome doesn't accept a base64 encoded string
// as value for applicationServerKey in pushManager.subscribe yet
// https://bugs.chromium.org/p/chromium/issues/detail?id=802280
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}


async function subscribeUserToPush() {
  return new Promise((resolve) => {
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(config.vapidPublicKey),
    };
    return resolve(swRegistration.pushManager.subscribe(subscribeOptions));
  })
  .then((pushSubscription) => pushSubscription);
}


function canReceivePushNotification() {
  if (!('serviceWorker' in navigator)) {
    // Service Worker isn't supported on this browser, disable or hide UI.
    return false;
  }
  if (!('PushManager' in window)) {
    // Push isn't supported on this browser, disable or hide UI.
    return false;
  }
  return true;
}


async function registerServiceWorker() {
  return navigator.serviceWorker.register('/service-worker.js')
  .then((registration) => {
    swRegistration = registration;
    return registration;
  });
}


async function askPermission() {
  return new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission(
      (result) => resolve(result));

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  });
}


async function getNotificationPermissionState() {
  if (navigator.permissions) {
    return navigator.permissions.query({name: 'notifications'})
    .then((result) => {
      return result.state;
    });
  }

  return new Promise((resolve) => {
    resolve(Notification.permission);
  });
}


export {
  canReceivePushNotification,
  registerServiceWorker,
  askPermission,
  subscribeUserToPush,
  getNotificationPermissionState,
};
