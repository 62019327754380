import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import moment from 'moment';

Vue.use(VueI18n);

let lang = localStorage.getItem('lang') || 'fr';
moment.locale(lang);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = new VueI18n({
  locale: lang || 'fr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentFallbackWarn: true,
});


function setLocale(value: string) {
  localStorage.setItem('lang', value);
  lang = i18n.locale = value;
  moment.locale(lang);
}

export { lang, setLocale, i18n, moment };
export default i18n;
