import Vue from 'vue';
import Axios from 'axios';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import './mixins/globalError';

import './styles/quasar.styl';
import 'quasar/dist/quasar.ie.polyfills';
import '@quasar/extras/material-icons/material-icons.css';
import './scss/default.scss';

// external components
import PortalVue from 'portal-vue';

// quasar framework specific
import Quasar from 'quasar/src/vue-plugin.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';;
Vue.use(Quasar, {
  plugins: [
    Dialog,
    Notify,
  ],
});

// moment.js
import { moment } from './i18n';
import VueMomentLib from 'vue-moment-lib';
Vue.use(VueMomentLib, { moment });

// portal
Vue.use(PortalVue);
Vue.component('SvgIcon', () => import('@/components/SvgIcon.vue'));
Vue.prototype.$http = Axios;
Vue.config.productionTip = false;

// internal filter
Vue.filter('uppercase', (value: string) => {
  return value.toUpperCase();
});


Vue.filter('secondstohuman', (value: number) => {
  if (value < 60) {
    return '< 1min';
  }
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value - hours * 3600) / 60);
  const sHours = hours.toString();
  let sMinutes = minutes.toString();
  if (sMinutes.length === 1) {
    sMinutes = '0' + sMinutes;
  }
  return sHours + 'h' + sMinutes;
});

Vue.filter('minutestohuman', (value: number) => {
  value = Math.round(value);
  if (value < 1) {
    return '< 1min';
  }
  const hours = Math.floor(value / 60);
  const minutes = (value - hours * 60);
  const sHours = hours.toString();
  let sMinutes = minutes.toString();
  if (sMinutes.length === 1) {
    sMinutes = '0' + sMinutes;
  }
  return sHours + 'h' + sMinutes;
});

Vue.mixin({
  methods: {
    cssIndicator: (value: number, online: boolean = true) => {
      if (online !== true) {
        return true;
      }
      return ['ccok', 'ccwarn', 'ccko'][value];
    },
    uuid(e: any) {
      if (e.__uuid) {
        return e.__uuid;
      }
      const key = Math.random().toString(16).slice(2);
      Vue.set(e, '__uuid', key);
      return e.uid;
    },
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
