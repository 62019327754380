import Vue from 'vue';
Vue.mixin({
  methods: {
    globalError(error) {
      if (error.reasonCode === 'accessdenied') {
        this.$q.notify({
          type: 'negative',
          message: 'Accès refusé',
        });
        this.$router.replace('/');
      } else if (error.response && error.response.data && error.response.data.detail) {
        this.$q.notify({
          type: 'negative',
          message: `API Error : ${error.response.data.detail}`,
        });
      } else {
        this.$q.notify({
          type: 'negative',
          message: `Une erreur est survenue ${error.message ? ' : ' + error.message : ''}`,
        });
      }
    },
  },
});
