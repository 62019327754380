import Vue from "vue";
import Router from "vue-router";
import ability from "./ability";

Vue.use(Router);

const rules = localStorage.getItem("rules");
if (rules !== null) {
  try {
    ability.update(JSON.parse(rules));
  } catch (error) {
    // rules may be invalid, throw them away
    localStorage.removeItem("rules");
  }
}

const router = new Router({
  mode: "history",
  // @ts-ignore
  // XXX if you remove it and include node in tsconfig.json,
  // then vuetype doesn't work anymore.
  // CF: https://github.com/ktsn/vuetype/issues/25
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import(/* webpackChunkName: "default" */ "./layouts/Default.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "home",
          component: () => import(/* webpackChunkName: "default" */ "./views/Home.vue"),
        },
        {
          path: "/help*",
          name: "help",
          component: () => import(/* webpackChunkName: "help" */ "./views/ExternalHelp.vue"),
        },
        {
          path: "/contact",
          name: "contact",
          component: () => import(/* webpackChunkName: "help" */ "./views/Contact.vue"),
        },
        {
          path: "/dashboard",
          component: () => import(/* webpackChunkName: "dashboard" */ "./views/RouterView.vue"),
          meta: {
            resource: "data-building",
          },
          children: [
            {
              path: "",
              component: () => import(/* webpackChunkName: "dashboard" */ "./views/Dashboard.vue"),
              name: "dashboard",
            },
            {
              path: "building/:id",
              component: () => import(/* webpackChunkName: "dashboard" */ "./views/DashboardBuilding.vue"),
              props: (route) => ({
                id: Number(route.params.id),
              }),
              name: "dashboard-building",
            },
          ],
        },
        {
          path: "/airquality",
          component: () => import(/* webpackChunkName: "dashboard" */ "./views/RouterView.vue"),
          meta: {
            resource: "airquality",
          },
          children: [
            {
              path: "",
              component: () => import(/* webpackChunkName: "dashboard" */ "./views/AirQuality.vue"),
              name: "airquality",
            },
            {
              path: "buildings",
              component: () => import(/* webpackChunkName: "dashboard" */ "./views/AirQualityBuildings.vue"),
              name: "airquality-buildings",
              meta: {
                resource: "data-building",
              },
            },
            {
              path: "building/:id",
              name: "airquality-building",
              component: () => import(/* webpackChunkName: "dashboard" */ "./views/AirQualityBuilding.vue"),
              props: (route) => ({
                id: Number(route.params.id),
              }),
              meta: {
                resource: "data-building",
              },
            },
            {
              path: "areas",
              name: "airquality-areas",
              component: () => import(/* webpackChunkName: "dashboard" */ "./views/AirQualityAreas.vue"),
              meta: {
                resource: "data-area",
              },
            },
            {
              path: "area/:areaId",
              component: () => import(/* webpackChunkName: "dashboard" */ "./views/AirQualityArea.vue"),
              props: (route) => ({
                areaId: Number(route.params.areaId),
              }),
              meta: {
                resource: "data-area",
              },
              children: [
                {
                  path: "",
                  name: "airquality-area",
                },
                {
                  path: "graph",
                  name: "airquality-area-graph",
                  component: () => import(/* webpackChunkName: "dashboard" */ "./views/AirQualityAreaGraph.vue"),
                },
              ],
            },
          ],
        },
        {
          path: "/outdoor/dashboard",
          name: "airquality-outdoor",
          component: () => import(/* webpackChunkName: "dashboard" */ "./views/AirQualityOutdoor.vue"),
          meta: {
            resource: "airquality-outdoor",
          },
        },
        {
          path: "/outdoor/dashboard/:analyzerId",
          name: "airquality-outdoor-analyzer",
          component: () => import(/* webpackChunkName: "dashboard" */ "./views/AirQualityOutdoorAnalyzer.vue"),
          props: (route) => ({
            analyzerId: Number(route.params.analyzerId),
          }),
          meta: {
            resource: "airquality-outdoor",
          },
        },
        {
          path: "/mobility",
          name: "airquality-mobility",
          component: () => import(/* webpackChunkName: "mobility" */ "./views/AirQualityMobility.vue"),
          meta: {
            resource: "airquality-mobility",
          },
        },
        {
          path: "/mobility/:id",
          name: "airquality-mobility-id",
          component: () => import(/* webpackChunkName: "mobility" */ "./views/AirQualityMobility.vue"),
          props: (route) => ({
            id: Number(route.params.id),
          }),
          meta: {
            resource: "airquality-mobility",
          },
        },
        {
          path: "/mobility/configure",
          name: "mobility-configure",
          component: () => import(/* webpackChunkName: "mobility" */ "./views/MobilityConfigure.vue"),
          meta: {
            resource: "airquality-mobility",
            action: "manage",
          },
        },
        {
          path: "/mobility/configure/:id",
          name: "mobility-configure-id",
          component: () => import(/* webpackChunkName: "mobility" */ "./views/MobilityConfigureAnalyzer.vue"),
          props: (route) => ({
            id: Number(route.params.id),
          }),
          meta: {
            resource: "airquality-mobility",
            action: "manage",
          },
        },
        {
          path: "/building/:id",
          redirect: "/dashboard/building/:id",
        },
        {
          path: "/admin",
          component: () => import(/* webpackChunkName: "admin" */ "./views/admin/Admin.vue"),
          children: [
            {
              path: "",
              name: "admin",
              redirect: { name: "admin-organisations" },
            },
            {
              path: "organisations",
              name: "admin-organisations",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/Organisations.vue"),
              meta: {
                resource: "global-organisation",
              },
            },
            {
              path: "organisation/:id",
              name: "admin-organisation",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/Organisation.vue"),
              props: (route) => ({
                id: Number(route.params.id),
              }),
              meta: {
                resource: "global-organisation",
                action: "manage",
              },
            },
            {
              path: "organisations/add",
              name: "admin-organisation-add",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/OrganisationAddEdit.vue"),
              props: (route) => ({
                id: null,
              }),
              meta: {
                resource: "global-organisation",
                action: "create",
              },
            },
            {
              path: "organisation/:id/edit",
              name: "admin-organisation-edit",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/OrganisationAddEdit.vue"),
              props: (route) => ({
                id: Number(route.params.id),
              }),
              meta: {
                resource: "global-organisation",
                action: "manage",
              },
            },
            {
              path: "members",
              name: "admin-members",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/Members.vue"),
              meta: {
                resource: "global-member",
              },
            },
            {
              path: "members/add",
              name: "admin-member-add",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/MemberAddEdit.vue"),
              props: (route) => ({
                id: null,
              }),
              meta: {
                resource: "global-member",
                action: "create",
              },
            },
            {
              path: "member/:id",
              name: "admin-member",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/Member.vue"),
              props: (route) => ({
                id: Number(route.params.id),
              }),
              meta: {
                resource: "global-member",
                action: "manage",
              },
            },
            {
              path: "member/:id/edit",
              name: "admin-member-edit",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/MemberAddEdit.vue"),
              props: (route) => ({
                id: Number(route.params.id),
              }),
              meta: {
                resource: "global-member",
                action: "manage",
              },
            },
            {
              path: "analyzers",
              name: "admin-analyzers",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/Analyzers.vue"),
              meta: {
                resource: "global-analyzer",
                action: "manage",
              },
            },
            {
              path: "analyzers/:id/edit",
              name: "admin-analyzer-edit",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/AnalyzerEdit.vue"),
              props: (route) => ({
                id: Number(route.params.id),
              }),
              meta: {
                resource: "global-analyzer",
                action: "manage",
              },
            },
            {
              path: "analyzers/:id/edit/calibration",
              name: "admin-analyzer-edit-calibration",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/AnalyzerEditCalibration.vue"),
              props: (route) => ({
                id: Number(route.params.id),
              }),
              meta: {
                resource: "global-analyzer",
                action: "manage",
              },
            },
            {
              path: "analyzer/:id",
              name: "admin-analyzer",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/Analyzer.vue"),
              props: (route) => ({
                id: Number(route.params.id),
              }),
              meta: {
                resource: "global-analyzer",
                action: "manage",
              },
            },
            {
              path: "subscription-plans",
              name: "admin-subscription-plans",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/SubscriptionPlans.vue"),
              meta: {
                resource: "global-subscription-plans",
                action: "manage",
              },
            },
            {
              path: "subscription-plans/add",
              name: "admin-subscription-plans-add",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/SubscriptionPlanAddEdit.vue"),
              props: (route) => ({
                id: null,
              }),
              meta: {
                resource: "global-subscription-plans",
                action: "create",
              },
            },
            {
              path: "subscription-plan/:id/edit",
              name: "admin-subscription-plan-edit",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/SubscriptionPlanAddEdit.vue"),
              props: (route) => ({
                id: Number(route.params.id),
              }),
              meta: {
                resource: "global-subscription-plans",
                action: "edit",
              },
            },
            {
              path: "dashboards",
              name: "admin-dashboards",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/Dashboards.vue"),
              meta: {
                resource: "global-dashboards",
                action: "manage",
              },
            },
            {
              path: "dashboards/add",
              name: "admin-dashboards-add",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/DashboardAddEdit.vue"),
              props: (route) => ({
                id: null,
              }),
              meta: {
                resource: "global-dashboards",
                action: "create",
              },
            },
            {
              path: "dashboard/:id/edit",
              name: "admin-dashboard-plan-edit",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/DashboardAddEdit.vue"),
              props: (route) => ({
                id: Number(route.params.id),
              }),
              meta: {
                resource: "global-dashboards",
                action: "edit",
              },
            },
            {
              path: "general",
              name: "admin-general",
              component: () => import(/* webpackChunkName: "admin" */ "./views/admin/General.vue"),
              props: (route) => ({
                id: Number(route.params.id),
              }),
              meta: {
                resource: "global-general",
                action: "edit",
              },
            },
          ],
        },
        {
          path: "/manage/buildings",
          name: "manage-buildings",
          component: () => import(/* webpackChunkName: "manage-buildings" */ "./views/ManageBuildings.vue"),
          meta: {
            resource: "building-informations",
            action: "update",
          },
        },
        {
          path: "/manage/buildings/edit",
          component: () => import(/* webpackChunkName: "manage-buildings" */ "./views/RouterView.vue"),
          meta: {
            resource: "building",
            action: "manage",
          },
          children: [
            {
              path: "",
              name: "buildings-tree-manage",
              component: () => import(/* webpackChunkName: "manage-buildings" */ "./views/ManageBuildingTree.vue"),
            },
            {
              path: "area/:id/thresholds",
              name: "admin-area-thresholds",
              component: () =>
                import(/* webpackChunkName: "manage-buildings" */ "./views/admin/AreaEditThresholds.vue"),
              props: (route) => ({
                id: Number(route.params.id),
              }),
              meta: {
                resource: "global-area",
                action: "manage",
              },
            },
            {
              path: "area/:id/presence",
              name: "admin-area-presence",
              component: () => import(/* webpackChunkName: "manage-buildings" */ "./views/AreaEditPresence.vue"),
              props: (route) => ({
                id: Number(route.params.id),
              }),
              meta: {
                resource: "area-presence",
                action: "update",
              },
            },
          ],
        },
        {
          path: "/manage/buildings/:id",
          component: () => import(/* webpackChunkName: "manage-buildings" */ "./views/ManageBuilding.vue"),
          props: (route) => ({
            id: Number(route.params.id),
          }),
          meta: {
            resource: "building-informations",
            action: "update",
          },
          children: [
            {
              path: "",
              name: "manage-building",
              redirect: { name: "manage-building-deploy" },
            },
            {
              path: "deploy",
              name: "manage-building-deploy",
              component: () => import(/* webpackChunkName: "manage-buildings" */ "./views/ManageBuildingDeploy.vue"),
            },
            {
              path: "users",
              name: "manage-building-users",
              component: () => import(/* webpackChunkName: "manage-buildings" */ "./views/ManageBuildingUsers.vue"),
            },
            {
              path: "reporting",
              name: "manage-building-reporting",
              component: () => import(/* webpackChunkName: "manage-buildings" */ "./views/ManageBuildingReporting.vue"),
              props: (route) => ({
                modelKey: "building",
              }),
            },
            {
              path: "informations",
              component: () => import(/* webpackChunkName: "manage-buildings" */ "./views/RouterViewBuilding.vue"),
              children: [
                {
                  path: "",
                  name: "manage-building-informations",
                  component: () =>
                    import(/* webpackChunkName: "manage-buildings" */ "./views/ManageBuildingInformations.vue"),
                  props: (route) => ({
                    modelKey: "building",
                  }),
                },
                {
                  path: "edit",
                  name: "manage-building-edit",
                  component: () => import(/* webpackChunkName: "manage-buildings" */ "./views/ManageBuildingEdit.vue"),
                  props: (route) => ({
                    id: Number(route.params.id),
                  }),
                },
                {
                  path: "area/:areaId/edit",
                  name: "manage-building-area-edit",
                  component: () =>
                    import(/* webpackChunkName: "manage-buildings" */ "./views/ManageBuildingAreaEdit.vue"),
                  props: (route) => ({
                    id: Number(route.params.id),
                    areaId: Number(route.params.areaId),
                    focusTab: route.params.focusTab || undefined,
                  }),
                },
              ],
            },
            {
              path: "reporting/:year/:period/:sub",
              name: "manage-building-reporting-by-period",
              component: () => import(/* webpackChunkName: "manage-buildings" */ "./views/ManageBuildingReporting.vue"),
              props: (route) => ({
                modelKey: "building",
                year: Number(route.params.year),
                period: route.params.period,
                sub: Number(route.params.sub),
              }),
            },
            {
              path: "area/:areaId/reporting/:year/:period/:sub",
              name: "manage-area-reporting-by-period",
              component: () => import(/* webpackChunkName: "manage-buildings" */ "./views/ManageBuildingReporting.vue"),
              props: (route) => ({
                modelKey: "area",
                areaId: Number(route.params.areaId),
                year: Number(route.params.year),
                period: route.params.period,
                sub: Number(route.params.sub),
              }),
            },
          ],
        },
        {
          path: "/notifications",
          name: "notifications",
          component: () => import(/* webpackChunkName: "prefs" */ "./views/Notifications.vue"),
          meta: {
            resource: "notification",
            action: "read",
          },
        },
        {
          path: "/me",
          redirect: { name: "member-me-preferences" },
          component: () => import(/* webpackChunkName: "memberme" */ "./views/MemberMe.vue"),
          children: [
            {
              path: "preferences",
              name: "member-me-preferences",
              component: () => import(/* webpackChunkName: "prefs" */ "./views/MemberMePreferences.vue"),
            },
            {
              path: "notifications",
              name: "member-me-notifications",
              component: () => import(/* webpackChunkName: "prefs" */ "./views/MemberMeNotifications.vue"),
              meta: {
                resource: "notification",
                action: "read",
              },
            },
            {
              path: "api",
              name: "member-me-api",
              component: () => import(/* webpackChunkName: "prefs" */ "./views/MemberMeApi.vue"),
            },
            {
              path: "data/export",
              name: "member-me-data-export",
              component: () => import(/* webpackChunkName: "prefs" */ "./views/MemberMeDataExport.vue"),
              meta: {
                resource: "data-export",
              },
            },
          ],
        },
        {
          path: "/me/webroles",
          name: "member-me-webroles",
          component: () => import(/* webpackChunkName: "prefs" */ "./views/MemberMeWebRoles.vue"),
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: () => import(/* webpackChunkName: "login" */ "./views/Login.vue"),
      meta: {
        guest: true,
      },
    },
    {
      path: "/member/activation/welcome",
      name: "member-activation",
      component: () => import(/* webpackChunkName: "member-activation" */ "./views/MemberActivation.vue"),
    },
    {
      path: "/member/reset-password",
      name: "member-reset-password",
      component: () => import(/* webpackChunkName: "member-activation" */ "./views/MemberPasswordReset.vue"),
    },
    {
      path: "/member/:id/:token/reset",
      name: "member-reset-password-confirm",
      component: () => import(/* webpackChunkName: "member-activation" */ "./views/MemberPasswordResetConfirm.vue"),
      props: (route) => ({
        id: Number(route.params.id),
        token: route.params.token,
      }),
    },
    {
      path: "/embed/:uid",
      name: "embed-widget",
      component: () => import(/* webpackChunkName: "embed-widget" */ "./views/EmbedWidget.vue"),
      props: (route) => ({
        uid: route.params.uid,
      }),
    },
    {
      path: "/external/reporting/:reportHash",
      name: "external-reporting",
      component: () => import(/* webpackChunkName: "external-reporting" */ "./views/ExternalReporting.vue"),
      props: (route) => ({
        reportHash: route.params.reportHash,
      }),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      const useritem = localStorage.getItem("user");
      if (useritem === null) {
        next();
      } else {
        const user = JSON.parse(useritem);
        if (to.matched.some((record) => record.meta.is_admin)) {
          if (user.is_admin === 1) {
            next();
          } else {
            next({ name: "home" });
          }
        } else {
          next();
        }
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("jwt") == null) {
      next();
    } else {
      next({ name: "home" });
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const canNavigate = to.matched.every((route: any) => {
    if (route.meta.resource === undefined) {
      return true;
    }
    return ability.can(route.meta.action || "read", route.meta.resource);
  });
  if (!canNavigate) {
    return next("/");
  }
  next();
});

export default router;
