import Vue from "vue";
import Vuex, { Store } from "vuex";
import axios from "axios";
import config from "./config";
import router from "./router";
import { setLocale, i18n } from "./i18n";
import { abilitiesPlugin } from "@casl/vue";
import ability from "./ability";
import eventBus from "./event-bus";
import Platform from 'quasar/src/plugins/Platform.js';;
import * as notifications from "@/notifications";

Vue.use(Vuex);
Vue.use(abilitiesPlugin, ability);

enum WEBPUSH_STATE {
  UNKNOWN = "UNKNOWN",
  SUPPORTED = "SUPPORTED",
  NOT_SUPPORTED = "NOT_SUPPORTED",
  SW_OK = "SW_OK",
  SW_KO = "SW_KO",
  SUBSCRIBED = "SUBSCRIBED",
  NOT_SUBSCRIBED = "NOT_SUBSCRIBED",
  SUBSCRIPTION_ERROR = "SUBSCRIPTION_ERROR",
  PERMISSION_GRANTED = "PERMISSION_GRANTED",
  PERMISSION_DENIED = "PERMISSION_DENIED",
}

const INDICATORS = {
  co2: i18n.t("FIELD_CO2_SHORT"),
  pm1: i18n.t("FIELD_PM1_SHORT"),
  pm25: i18n.t("FIELD_PM25_SHORT"),
  pm10: i18n.t("FIELD_PM10_SHORT"),
  co: i18n.t("FIELD_CO_SHORT"),
  cov: i18n.t("FIELD_COV_SHORT"),
  no2: i18n.t("FIELD_NO2_SHORT"),
  no2o3: i18n.t("FIELD_NO2O3_SHORT"),
};

const paramsSerializer = (params: any) => {
  const searchParams = new URLSearchParams();
  for (const key of Object.keys(params)) {
    const param = params[key];
    if (param === undefined) {
      continue;
    } else if (param === null) {
      continue;
    } else if (Array.isArray(param)) {
      for (const p of param) {
        searchParams.append(key, p);
      }
    } else {
      searchParams.append(key, param);
    }
  }
  return searchParams.toString();
};

function apiHead(path: string, params?: any, isPublic?: boolean, axiosParams?: any) {
  return new Promise((resolve, reject) => {
    if (isPublic !== true) {
      if (axios.defaults.headers.common["Authorization"] === undefined) {
        return;
      }
    }

    axiosParams = axiosParams || {};

    axios
      .head(path, {
        params: params || {},
        paramsSerializer,
        ...axiosParams,
      })
      .then((response: any) => {
        if (axiosParams.useRaw === true) {
          resolve(response);
        } else {
          resolve(response.data);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}
function apiGet(path: string, params?: any, isPublic?: boolean, axiosParams?: any) {
  return new Promise((resolve, reject) => {
    if (isPublic !== true) {
      if (axios.defaults.headers.common["Authorization"] === undefined) {
        return;
      }
    }

    axiosParams = axiosParams || {};

    axios
      .get(path, {
        params: params || {},
        paramsSerializer,
        ...axiosParams,
      })
      .then((response: any) => {
        if (axiosParams.useRaw === true) {
          resolve(response);
        } else {
          resolve(response.data);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

// Configure axios & api
axios.defaults.baseURL = config.api_url;
Vue.mixin({
  methods: {
    $apiget: apiGet,
  },
});

const store = new Vuex.Store({
  state: {
    userInfo: null,

    // for listing
    ready: false,
    organisation: null,
    buildingsLoaded: false,
    buildings: [],

    // if set, it will display a message at the top,
    // closable by the user.
    errorMessage: null,

    // for edition
    building: null,

    // notifications
    webpushState: WEBPUSH_STATE.UNKNOWN,
    notificationCountUnseen: 0,
    notificationCountNew: 0,
    notificationLasts: [],

    // cache
    analyzers: {},
    indicators: [],
  },

  mutations: {
    logout(state, payload) {
      state.userInfo = null;
      state.organisation = null;
      state.buildingsLoaded = false;
      state.buildings = [];
      state.building = null;
      state.ready = false;
    },
    setReady(state, payload) {
      state.ready = true;
    },
    setOrganisation(state, payload) {
      Vue.set(state, "organisation", payload);
    },
    setBuildings(state, payload) {
      Vue.set(state, "buildings", payload);
      state.buildingsLoaded = true;
    },
    setBuilding(state, payload) {
      Vue.set(state, "building", payload);
    },
    setUserInformation(state, payload) {
      Vue.set(state, "userInfo", payload);
      ability.update(payload.rules);
      localStorage.setItem("rules", JSON.stringify(payload.rules));
    },
    setAnalyzer(state, payload) {
      state.analyzers[payload.analyzer.id] = payload.analyzer;
    },
    setIndicators(state, payload) {
      state.indicators = payload;
    },
    notificationRead(state, notificationId) {
      if (state.notificationCountUnseen > 0) {
        state.notificationCountUnseen = state.notificationCountUnseen - 1;
      }
      if (state.notificationCountNew > 0) {
        state.notificationCountNew = state.notificationCountNew - 1;
      }
      state.notificationLasts.forEach((entry: any) => {
        if (entry.id === notificationId) {
          entry.seen = true;
        }
      });
    },
    notificationAllRead(state, payload) {
      state.notificationCountNew = 0;
      state.notificationCountUnseen = 0;
    },
    notificationAllAck(state, payload) {
      state.notificationCountNew = 0;
    },
    notificationDeleteAll(state, payload) {
      state.notificationLasts = [];
      state.notificationCountNew = 0;
      state.notificationCountUnseen = 0;
    },
    webpushState(state, payload) {
      state.webpushState = payload;
    },
  },

  getters: {
    getIndicatorText: (state) => (indicator: string) => {
      return INDICATORS[indicator];
    },

    getAreaFromId: (state) => (areaId: number) => {
      let building: any;
      let level: any;
      let area: any;
      for (building of state.buildings) {
        for (level of building["levels"]) {
          for (area of level["areas"]) {
            if (area["id"] === areaId) {
              return area;
            }
          }
        }
      }
    },

    getAnalyzerFromId: (state) => (analyzerId: number) => {
      return state.analyzers[analyzerId] || null;
    },

    getBuildingFromId: (state) => (buildingId: number) => {
      let building: any;
      for (building of state.buildings) {
        if (building["id"] === buildingId) {
          return building;
        }
      }
    },

    getBuildingFromAreaId: (state) => (areaId: number) => {
      let building: any;
      let level: any;
      let area: any;
      for (building of state.buildings) {
        for (level of building["levels"]) {
          for (area of level["areas"]) {
            if (area["id"] === areaId) {
              return building;
            }
          }
        }
      }
    },

    getAreasFromBuilding: (state, getters) => (building: any) => {
      const areas: any[] = [];
      let level: any = null;
      for (level of building.levels) {
        for (const area of level.areas) {
          area.building_id = building.id;
          areas.push(area);
        }
      }
      return areas;
    },

    getAreas: (state, getters) => () => {
      const areas: any[] = [];
      let building: any = null;
      let level: any = null;
      for (building of state.buildings) {
        for (level of building.levels) {
          for (const area of level.areas) {
            area.building_id = building.id;
            areas.push(area);
          }
        }
      }
      return areas;
    },

    getBuildingIcon: (state, getters) => (building: any) => {
      if (building === undefined || building.category === undefined) {
        return "asvg:bat-erp";
      }
      const category = building.category[0];
      const subcategory = building.category[1];
      if (category === "tertiaire") {
        return "asvg:bat-bureau";
      }
      if (category === "industrie") {
        if (subcategory === "atelier") {
          return "asvg:bat-atelier";
        }
        return "asvg:bat-industrie";
      }
      if (category === "residentiel") {
        return "asvg:bat-logement";
      }
      return "asvg:bat-erp";
    },
  },

  actions: {
    async checkWebPush({ commit, state, dispatch }, payload) {
      if (!notifications.canReceivePushNotification()) {
        commit("webpushState", WEBPUSH_STATE.NOT_SUPPORTED);
        return;
      } else {
        commit("webpushState", WEBPUSH_STATE.SUPPORTED);
      }

      await notifications.getNotificationPermissionState().then((result: any) => {
        if (result === "granted") {
          commit("webpushState", WEBPUSH_STATE.PERMISSION_GRANTED);
        } else {
          commit("webpushState", WEBPUSH_STATE.PERMISSION_DENIED);
        }
      });

      if (state.webpushState === WEBPUSH_STATE.PERMISSION_DENIED) {
        return;
      }

      await notifications
        .registerServiceWorker()
        .then((result) => {
          commit("webpushState", WEBPUSH_STATE.SW_OK);
        })
        .catch((e) => {
          commit("webpushState", WEBPUSH_STATE.SW_KO);
        });

      if (state.webpushState === WEBPUSH_STATE.SW_KO) {
        return;
      }

      await navigator.serviceWorker.ready.then((serviceWorkerRegistration: any) => {
        // Do we already have a push message subscription?
        serviceWorkerRegistration.pushManager.getSubscription().then((subscription: any) => {
          if (subscription) {
            commit("webpushState", WEBPUSH_STATE.SUBSCRIBED);
          } else {
            commit("webpushState", WEBPUSH_STATE.NOT_SUBSCRIBED);
          }
        });
      });
    },

    async setupNotificationsPush({ commit, state, dispatch }, payload) {
      if (notifications.canReceivePushNotification()) {
        commit("webpushState", WEBPUSH_STATE.SUPPORTED);
      } else {
        commit("webpushState", WEBPUSH_STATE.NOT_SUPPORTED);
        return;
      }

      await notifications
        .registerServiceWorker()
        .then((result) => {
          commit("webpushState", WEBPUSH_STATE.SW_OK);
        })
        .catch((e) => {
          commit("webpushState", WEBPUSH_STATE.SW_KO);
        });

      if (state.webpushState === WEBPUSH_STATE.SW_KO) {
        return;
      }

      await notifications.askPermission().then((result) => {
        if (result === "granted") {
          commit("webpushState", WEBPUSH_STATE.PERMISSION_GRANTED);
        } else {
          commit("webpushState", WEBPUSH_STATE.PERMISSION_DENIED);
        }
      });

      if (state.webpushState === WEBPUSH_STATE.PERMISSION_DENIED) {
        return;
      }

      await notifications
        .subscribeUserToPush()
        .then((subscriptionInfo) => {
          axios
            .post(`${config.api_url}/notifications/webpush/subscriptions/add`, {
              subscriptionInfo,
            })
            .then((result) => {
              commit("webpushState", WEBPUSH_STATE.SUBSCRIBED);
            })
            .catch((e) => {
              commit("webpushState", WEBPUSH_STATE.SUBSCRIPTION_ERROR);
              console.error("subscribeUserToPush =>", e);
            });
        })
        .catch((e) => {
          commit("webpushState", WEBPUSH_STATE.SUBSCRIPTION_ERROR);
          console.error("subscribeUserToPush =>", e);
        });
    },

    cancelNotificationPush({ commit, state, dispatch }, payload) {
      navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        serviceWorkerRegistration.pushManager.getSubscription().then((subscriptionInfo: any) => {
          subscriptionInfo
            .unsubscribe()
            .then((successful) => {
              axios
                .post(`${config.api_url}/notifications/webpush/subscriptions/delete`, {
                  subscriptionInfo,
                })
                .then((result) => {
                  commit("webpushState", WEBPUSH_STATE.NOT_SUBSCRIBED);
                })
                .catch((e) => {
                  commit("webpushState", WEBPUSH_STATE.SUBSCRIPTION_ERROR);
                });
            })
            .catch((e) => {
              commit("webpushState", WEBPUSH_STATE.SUBSCRIPTION_ERROR);
            });
        });
      });
    },

    login({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/oauth/v2/token`, {
            username: payload.email,
            password: payload.password,
          })
          .then((response) => {
            const token = response.data.access_token;
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            ability.update(response.data.rules);
            localStorage.setItem("jwt", response.data.access_token);
            localStorage.setItem("rules", JSON.stringify(response.data.rules));
            router.push({ path: "/" });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    logout({ commit, state, dispatch }, payload) {
      delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem("jwt");
      localStorage.removeItem("rules");
      commit("logout");
    },

    loadOrganisation({ commit, state, dispatch }, payload) {
      return apiGet("/organisation").then((response: any) => {
        commit("setOrganisation", response);
        return response;
      });
    },

    loadOrganisationMembers({ commit, state, dispatch }, payload) {
      return apiGet("/organisation/members");
    },

    loadBuilding({ commit, state, dispatch }, payload) {
      return apiGet(`/organisation/building/${payload.buildingId}`, payload.params).then((response: any) => {
        commit("setBuilding", response);
        return response;
      });
    },

    ensureBuildings({ commit, state, dispatch }, payload) {
      if (state.buildingsLoaded === true) {
        return new Promise((resolve, reject) => {
          resolve(state.buildings);
        });
      }
      return dispatch("loadBuildings", payload);
    },

    ensureBuilding({ commit, state, dispatch }, payload) {
      if (state.building !== null) {
        const building: any = state.building;
        if (building.id === payload.buildingId) {
          return new Promise((resolve, reject) => {
            resolve(state.building);
          });
        }
      }
      return dispatch("loadBuilding", payload);
    },

    loadBuildingInformations({ commit, state, dispatch }, payload) {
      return apiGet(`/organisation/building/${payload.buildingId}/informations`, payload.params);
    },

    saveBuildingInformations({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const buildingId = payload.buildingId;
        axios
          .post(`${config.api_url}/organisation/building/${buildingId}/informations`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    loadArea({ commit, state, dispatch }, payload) {
      return apiGet(`/area/${payload.areaId}`, payload.params).then((response: any) => {
        return response;
      });
    },

    loadAdminAreaThresholds({ commit, state, dispatch }, payload) {
      return apiGet(`/admin/area/${payload.areaId}/thresholds`);
    },

    editAdminAreaThresholds({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const areaId = payload.areaId;
        axios
          .patch(`${config.api_url}/admin/area/${areaId}/thresholds`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    loadAreaInformations({ commit, state, dispatch }, payload) {
      return apiGet(`/area/${payload.areaId}/informations`, payload.params);
    },

    saveAreaInformations({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const areaId = payload.areaId;
        axios
          .post(`${config.api_url}/area/${areaId}/informations`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    loadBuildings({ commit, state, dispatch }, payload) {
      return apiGet("/organisation/buildings?recursive=true").then((response) => {
        commit("setBuildings", response);
        return response;
      });
    },

    checkNotificationPermissions({ commit, state, dispatch }, payload) {
      if (!Platform.is.desktop || "Notification" in window === false) {
        return;
      }
      if (Notification.permission === "default") {
        Notification.requestPermission();
      }
    },

    preload({ commit, state, dispatch }, payload) {
      const token = localStorage.getItem("jwt");
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      if (state.ready === true) {
        return Promise.all([]);
      }
      return Promise.all([
        this.dispatch("loadMemberMe"),
        this.dispatch("loadOrganisation"),
        this.dispatch("loadBuildings"),
        this.dispatch("checkWebPush"),
        // this.dispatch('checkNotificationPermissions'),
      ]).then(() => {
        this.dispatch("loadNotifications");
      });
    },

    loadNotifications({ commit, state, dispatch }, payload) {
      if (!ability.can("read", "notification")) {
        return;
      }
      return new Promise((resolve, reject) => {
        if (axios.defaults.headers.common["Authorization"] === undefined) {
          return;
        }
        axios
          .get(`${config.api_url}/notifications`)
          .then((response) => {
            this.dispatch("processNotifications", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    processNotifications({ commit, state, dispatch, getters }, response) {
      state.notificationCountUnseen = response["unseen"];
      state.notificationCountNew = response["new"];
      // merge both current notification and last notification, trim to the last 10
      const notificationsEntries: any = state.notificationLasts.slice();
      const currentIds = notificationsEntries.map((entry: any) => entry.id);
      response.notifications
        .slice()
        .reverse()
        .forEach((entry: any) => {
          if (currentIds.includes(entry.id)) {
            return;
          }
          notificationsEntries.unshift(entry);
        });
      state.notificationLasts = notificationsEntries.slice(0, 3);
    },

    notificationRead({ commit, state, dispatch, getters }, payload) {
      commit("notificationRead", payload);
      axios.patch(`${config.api_url}/notification/${payload}/read`);
    },

    notificationAllAck({ commit, state, dispatch, getters }, payload) {
      commit("notificationAllAck");
      axios.patch(`${config.api_url}/notifications/ack`);
    },

    loadAreaData({ commit, state, dispatch }, payload) {
      return apiGet(`/area/${payload.areaId}/data`, {
        indicators: payload.indicators,
        dt_start: payload.dt_start,
        dt_end: payload.dt_end,
        smooth: payload.smooth,
      });
    },

    loadAreaPresence({ commit, state, dispatch }, payload) {
      return apiGet(`/area/${payload.areaId}/presence`);
    },

    loadBuildingsDataLast({ commit, state, dispatch }) {
      return apiGet(`/buildings/data/last`);
    },

    editAreaPresence({ commit, state, dispatch }, payload) {
      const areaId = payload.areaId;
      return new Promise((resolve, reject) => {
        axios
          .patch(`${config.api_url}/area/${areaId}/presence`, payload.payload)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    loadBuildingPresence({ commit, state, dispatch }, payload) {
      return apiGet(`/building/${payload.buildingId}/presence`);
    },

    editBuildingPresence({ commit, state, dispatch }, payload) {
      const buildingId = payload.buildingId;
      return new Promise((resolve, reject) => {
        axios
          .patch(`${config.api_url}/building/${buildingId}/presence`, payload.payload)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    loadAnalyzerBatteryLast({ commit, state, dispatch }, payload) {
      return apiGet(`/analyzer/${payload.analyzerId}/battery/last`);
    },

    loadAnalyzerData({ commit, state, dispatch }, payload) {
      return apiGet(`/analyzer/${payload.analyzerId}/data`, {
        indicators: payload.indicators,
        dt_start: payload.dt_start,
        dt_end: payload.dt_end,
      });
    },

    loadAnalyzerLocation({ commit, state, dispatch }, payload) {
      return apiGet(`/analyzer/${payload.analyzerId}/location`);
    },

    loadAnalyzers({ commit, state, dispatch }, payload) {
      let params: any;
      if (payload !== undefined && payload.params !== undefined) {
        params = payload.params;
      }
      return apiGet(`/analyzers`, params);
    },

    loadAnalyzer({ commit, state, dispatch }, payload) {
      return apiGet(`/analyzer/${payload.analyzerId}`);
    },

    loadAnalyzerMembers({ commit, state, dispatch }, payload) {
      return apiGet(`/analyzer/${payload.analyzerId}/members`);
    },

    addAnalyzerMember({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const analyzerId = payload.analyzerId;
        axios
          .post(`${config.api_url}/analyzer/${analyzerId}/members`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateAnalyzerMember({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const analyzerId = payload.analyzerId;
        const userId = payload.userId;
        axios
          .post(`${config.api_url}/analyzer/${analyzerId}/member/${userId}`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    removeAnalyzerMember({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const analyzerId = payload.analyzerId;
        const userId = payload.userId;
        axios
          .delete(`${config.api_url}/analyzer/${analyzerId}/member/${userId}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    loadAnalyzerLastData({ commit, state, dispatch }, payload) {
      return apiGet(`/analyzer/${payload.analyzerId}/data/last`, payload.params);
    },

    editAnalyzer({ commit, state, dispatch }, payload) {
      const analyzerId = payload.analyzerId;
      return new Promise((resolve, reject) => {
        axios
          .patch(`${config.api_url}/analyzer/${analyzerId}`, payload.payload)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    // Tree management

    addArea({ commit, state, dispatch }, payload) {
      const buildingId = payload.buildingId;
      const levelId = payload.levelId;
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/organisation/building/${buildingId}/areas`, {
            name: payload.name,
            level_id: levelId,
          })
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    editArea({ commit, state, dispatch }, payload) {
      const buildingId = payload.buildingId;
      const levelId = payload.levelId;
      const areaId = payload.areaId;
      const data: any = {
        name: payload.name,
      };
      if (payload.analyzerId !== undefined) {
        data.analyzer_id = payload.analyzerId;
      }
      return new Promise((resolve, reject) => {
        axios
          .patch(`${config.api_url}/organisation/building/${buildingId}/area/${areaId}`, data)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    deleteArea({ commit, state, dispatch }, payload) {
      const buildingId = payload.buildingId;
      const levelId = payload.levelId;
      const areaId = payload.areaId;
      return new Promise((resolve, reject) => {
        axios
          .delete(`${config.api_url}/organisation/building/${buildingId}/area/${areaId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    addLevel({ commit, state, dispatch }, payload) {
      const buildingId = payload.buildingId;
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/organisation/building/${buildingId}/levels`, { name: payload.name })
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    editLevel({ commit, state, dispatch }, payload) {
      const buildingId = payload.buildingId;
      const levelId = payload.levelId;
      return new Promise((resolve, reject) => {
        const data = { name: payload.name };
        if (payload.geojson !== undefined) {
          data["geojson"] = payload.geojson;
        }
        axios
          .patch(`${config.api_url}/organisation/building/${buildingId}/level/${levelId}`, data)
          .then((response: any) => {
            if (payload.image !== undefined) {
              const fdata = new FormData();
              fdata.append("image", payload.image);
              axios
                .patch(`${config.api_url}/organisation/building/${buildingId}/level/${levelId}/image`, fdata)
                .then((fresponse: any) => {
                  resolve(fresponse.data);
                })
                .catch((error: any) => {
                  reject(error);
                });
            } else {
              resolve(response.data);
            }
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    deleteLevel({ commit, state, dispatch }, payload) {
      const buildingId = payload.buildingId;
      const levelId = payload.levelId;
      return new Promise((resolve, reject) => {
        axios
          .delete(`${config.api_url}/organisation/building/${buildingId}/level/${levelId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    addBuilding({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/organisation/buildings`, payload)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    editBuilding({ commit, state, dispatch }, payload) {
      const buildingId = payload.buildingId;
      return new Promise((resolve, reject) => {
        axios
          .patch(`${config.api_url}/organisation/building/${buildingId}`, payload.payload)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    deleteBuilding({ commit, state, dispatch }, payload) {
      const buildingId = payload.buildingId;
      return new Promise((resolve, reject) => {
        axios
          .delete(`${config.api_url}/organisation/building/${buildingId}`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    // ADMIN API

    loadAdminAnalyzers({ commit, state, dispatch }, payload) {
      return apiGet("/admin/analyzers", payload);
    },

    loadOrganisations({ commit, state, dispatch }, payload) {
      return apiGet("/admin/organisations", payload);
    },

    takeOverOrganisation({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/admin/organisation/${payload.organisationId}/takeOver`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    activateOrganisation({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/admin/organisation/${payload.organisationId}/activate`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deactivateOrganisation({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/admin/organisation/${payload.organisationId}/deactivate`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    loadOrganisationById({ commit, state, dispatch }, payload) {
      return apiGet(`/admin/organisation/${payload.organisationId}`);
    },

    addOrganisation({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/admin/organisations`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    editOrganisation({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${config.api_url}/admin/organisation/${payload.organisationId}`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    ensureIndicators({ commit, state, dispatch }, payload) {
      if (state.indicators.length > 0) {
        return;
      }
      apiGet(`/indicators/list`).then((response: any) => {
        commit("setIndicators", response);
      });
    },

    loadSubscriptionPlanById({ commit, state, dispatch }, payload) {
      return apiGet(`/admin/subscription-plan/${payload.subscriptionPlanId}`);
    },

    loadSubscriptionPlans({ commit, state, dispatch }, payload) {
      return apiGet(`/admin/subscription-plans`, payload);
    },

    loadSubscriptionPlansOptions({ commit, state, dispatch }, payload) {
      return apiGet(`/admin/subscription-plans/options`);
    },

    addSubscriptionPlan({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/admin/subscription-plans`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    editSubscriptionPlan({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${config.api_url}/admin/subscription-plan/${payload.subscriptionPlanId}`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    loadUsers({ commit, state, dispatch }, payload) {
      return apiGet(`/admin/users`, payload);
    },

    loadUserById({ commit, state, dispatch }, payload) {
      return apiGet(`/admin/user/${payload.userId}`);
    },

    loadMemberMe({ commit, state, dispatch }, payload) {
      return apiGet(`/member/me`).then((response: any) => {
        commit("setUserInformation", response);
        setLocale(response.lang);
        return response;
      });
    },

    loadMemberMePreferences({ commit, state, dispatch }, payload) {
      return apiGet(`/member/me/prefs`);
    },

    editMemberMePreferences({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${config.api_url}/member/me/prefs`, payload.payload)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    loadMemberApiKeys({ commit, state, dispatch }, payload) {
      return apiGet(`/member/apikeys`);
    },

    deleteMemberApiKey({ commit, state, dispatch }, payload) {
      const apiKeyId = payload.apiKeyId;
      return new Promise((resolve, reject) => {
        axios
          .delete(`${config.api_url}/member/apikey/${apiKeyId}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    createMemberApiKey({ commit, state, dispatch }, payload) {
      const apiKeyId = payload.apiKeyId;
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/member/apikeys`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },

    loadMemberActivationInformation({ commit, state, dispatch }, payload) {
      return apiGet(`/member/activation`, { token: payload.token }, true);
    },

    activateMember({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/member/activation`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    resetPasswordAsk({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/member/reset-password/ask`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    resetPassword({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/member/reset-password`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    sendResetPasswordEmail({ commit, state, dispatch }, payload) {
      return apiGet(`/admin/user/${payload.userId}/send-reset-password`);
    },

    sendWelcomeEmail({ commit, state, dispatch }, payload) {
      return apiGet(`/admin/user/${payload.userId}/send-welcome`);
    },

    addUser({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/admin/users`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    editUser({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${config.api_url}/admin/user/${payload.userId}`, payload.payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    activateUser({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/admin/user/${payload.userId}/activate`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deactivateUser({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.api_url}/admin/user/${payload.userId}/deactivate`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      if (error.response.status === 401) {
        store.dispatch("logout");
        window.location.href = "/";
      }
      if (error.response.status === 403) {
        return Promise.reject({
          message: "Accès refusé",
          reasonCode: "accessdenied",
        });
      }

      return Promise.reject({
        status: error.response.status,
        response: error.response,
        message: error.response.data.error,
        reasonCode: "apperror",
      });
    } catch (TypeError) {
      if (error.response === undefined) {
        return Promise.reject({
          status: "unknown",
          response: error.toString(),
          message: error.toString(),
        });
      }
      return Promise.reject({
        status: error.response.status,
        response: error.response,
        message: error.toString(),
      });
    }
  }
);

export { apiGet, apiHead };
export default store;
