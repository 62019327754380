/// <reference types="node" />

import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

let apiUrl: string;
let socketIOUrl: string;

if (document.location.hostname.endsWith('.ngrok.io')) {
    const parts = document.location.hostname.split('.');
    parts[0] = parts[0] + '-api';
    const hostname = parts.join('.');
    apiUrl = document.location.protocol + '//' + hostname;
    socketIOUrl = 'wss://' + hostname;
} else if (process.env.VUE_APP_API_URL) {
    apiUrl = process.env.VUE_APP_API_URL;
    socketIOUrl = process.env.VUE_APP_API_URL;
} else if (process.env.NODE_ENV === 'production') {
    apiUrl = process.env.API_URL || 'https://api.cozyconnect.com';
    socketIOUrl = process.env.API_URL || 'wss://api.cozyconnect.com';
    Sentry.init({
        dsn: 'https://2558860b4a964bccbe258b6d310acc07@sentry.cozyconnect.com/4',
        integrations: [new Integrations.Vue({Vue, attachProps: true})],
        ignoreErrors: ['ResizeObserver loop limit exceeded'],
      });
} else {
    apiUrl = document.location.protocol + '//' + document.location.hostname + ':5000';
    socketIOUrl = 'ws://:5000';
}

const projects: any = {
    cozyconnect: {
        name: 'CozyConnect',
    },
    veilair: {
        name: 'Veilair',
    },
};

const vapidPublicKey = process.env.VUE_APP_VAPID_PUBLIC_KEY;

export default {
    api_url: apiUrl,
    socketIOUrl,
    projects,
    vapidPublicKey,
};
