






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';


@Component
export default class App extends Vue {
  public created() {
    this.$q.iconMapFn = (iconName: string) => {
      if (iconName.startsWith('asvg:') === true) {
        const name = iconName.substring(5);
        let icon = require(`@/assets/svgs/symbols/${name}.svg`);
        if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
          icon = icon.default;
        } else {
          icon = icon.url;
        }
        return { icon: 'svguse:' + icon };
      }
    };
  }
}
